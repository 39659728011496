var COOKIE = COOKIE || {};

/*   
    *  COOKIE CONSENT BAR (Alex Ghirelli)
    *  Usage: COOKIE.cookieconsent({...});
    * 
    *  Settings:
    *  agreement --> i valori possono essere ('click', 'scroll'), questo parametro esprime il tipo di accettazione, se tramite click su un pulsante o allo scroll della pagina
    *  expires --> è la durata entro la quale rimarranno attivi i cookie "CookieConsent", "CookieDecline"
    *  anchor --> è di tipo boolean, se settato a "true", fa in modo che al click su qualsiasi link presente in pagina venga accettata l'informativa sui cookie
    * 
    *  Metodi:
    *  changeType()
    *  changeSRC_VIDEO()
    *  agree()
    *  disagree()
    *  disableStep()
    */

(function () {
    "use strict";
    
    var $agree = $('.accept');
    var $disagree = $('.decline');
    var $cookie_law = $('.cookie_law');
    
    var $CookieConsent = $.cookie('CookieConsent');
    var $CookieDecline = $.cookie('CookieDecline');
    
    /* PUBLIC METHODS */

    COOKIE.cookieconsent = function (options) {
        options = options || {};
        
        var defaults = {
            agreement: 'click',
            expires: 30,
            anchor: false
        };
        
        options = $.extend(defaults, options);   
        
        if ($CookieConsent) {
            changeType();
            if ($cookie_law) {
                changeSRC_VIDEO();
            }
        }
        
        if (!$CookieConsent && !$CookieDecline) {
            $('#cookieBar').removeClass('cookie_hide');
            trackPageViewGA(true);
        }
        
        if ($CookieDecline || $CookieConsent) {
            options = {
                agreement: 'click'
            }
            
            $(document).trigger("OnCookieChoice", $CookieConsent === 'true');
        }
        
        if ($CookieConsent) {
            trackPageViewGA(false);
        }
        
        if ($CookieDecline) {
            trackPageViewGA(true);
        }
        
        if (options.agreement == 'scroll') {
            $(window).scroll(function() {
                $('.cookiebar').fadeOut(1000);
                $.cookie('CookieConsent', 'true', { 
                    expires: options.expires, path: '/' 
                });
                setTimeout(function() { 
                    location.reload();
                }, 1500);
            });
        }
        
        if (options.anchor == true) {
            $("a").each(function() {
                var el = $(this);
                el.click(function() {
                    $('.cookiebar').fadeOut(1000);
                    $.cookie('CookieConsent', 'true', { 
                        expires: options.expires, path: '/' 
                    });
                    setTimeout(function() { 
                        location.reload();
                    }, 1500);
                })
            })
        }
        
        agree(options);
        disagree(options);
    }    
    
    /* PRIVATE METHODS */    

    function changeType() {
        $('.cookie_law[type="text/plain"]').each(function(){
            var $el = $(this);
            $el.after('<script type="text/javascript">'+ $el.html() +'</script>');
        });
        $('.cookie_law[type="text/plain"]').remove();
    }
    
    function changeSRC_VIDEO() {
        $('.cookie_law[data-url]').each(function() {
            var $el = $(this);
            var val = $el.attr('data-url');
            $el.removeAttr('data-url')
               .attr('src', val);
        });
    }
    
    function agree(options) {
        $agree.click(function ()  {
            $('.cookiebar').fadeOut(1000);
            $.removeCookie('CookieDecline');
            $.cookie('CookieConsent', 'true', { 
                expires: options.expires, path: '/' 
            });
            setTimeout(function() { 
                location.reload();
            }, 1500);
        })
    }
    
    function disagree(options) {
        $disagree.click(function ()  {
            $('.cookiebar').fadeOut(1000);
            $.cookie('CookieDecline', 'true', { 
                expires: options.expires, path: '/' 
            });
            $.removeCookie('CookieConsent');
            setTimeout(function() { 
                location.reload();
            }, 1500);
        })
    }

    // UTILITY METHODS x GOOGLE ANALYTICS

    function hasUniversalGA() {
        return typeof ga !== 'undefined';
    }

    function hasClassicGA() {
        return typeof _gaq !== 'undefined';
    }

    function trackPageViewGA(anonymizeIp) {
        if (hasUniversalGA()) {
            ga('send', 'pageview', {
                'anonymizeIp': anonymizeIp
            });
        } else if (hasClassicGA()) {
            if (anonymizeIp) {
                _gaq.push(['_gat._anonymizeIp']);
            }
            _gaq.push(['_trackPageview']);
            _gaq.push(['_trackPageLoadTime']);
        }
    }

})();