/* CMP PAGINATOR (Ver 0.1) */

var CMP = CMP || {};

(function ($) {
    "use strict";

    CMP.paginator = function(strSelector, options) {
        options = options || {};
        
        var defaults = {
            total: 1,          // total pages
            page: 1,           // default page
            maxVisible: 5,     // visible pagination
            leaps: true,        // next/prev leaps through maxVisible
            onPageChange: $.noop // Funzione di callback richiamata quando si passa pagina
        };
        options = $.extend(defaults, options);

        if ($.fn.bootpag) {
            $(strSelector).bootpag(options).on("page", function (event, num) {

                var _container = $(this).attr('data-content');
                if (_container)
                {
                    $(_container).find('.page:visible').hide();
                    $(_container).find('.page' + num).show();
                }

                options.onPageChange(num);

            });
        }
    }

})(jQuery);